/* eslint-disable @next/next/no-css-tags */
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import '../styles/global.css'

import { isClient } from '@mr-yum/frontend-core/dist/support/env'
import { Toaster } from '@mr-yum/frontend-ui'
import compose from 'lodash/fp/compose'
import { minimatch } from 'minimatch'
import App, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import nookies from 'nookies'
import React, { useEffect, useMemo } from 'react'
import { ReactTagManager } from 'react-gtm-ts'

import { EonxEnvController } from '@/components/EonX/EonxEnv'
import { LanguageProvider } from '@/components/Lang/LanguageProvider'
import { DefaultMeta } from '@/components/Meta/DefaultMeta'
import { VenueMetaImage } from '@/components/Meta/VenueMetaImage'
import { AmplitudeIdentify } from '@/components/Tracking/AmplitudeTracking'
import { FacebookTracking } from '@/components/Tracking/FacebookTracking'
import { UrlParamsSpy } from '@/components/UrlParamsSpy/UrlParamsSpy'
import { ThemeOverrides } from '@/components/VenueBranding/ThemeOverrides'
import { ThemeVariables } from '@/components/VenueBranding/ThemeVariables'
import { LoggerProvider } from '@/contexts/LoggerContext'
import { StripeContextProvider } from '@/contexts/StripeContext'
import { ThemeProvider } from '@/contexts/ThemeContext'
import { UnleashContextProvider } from '@/contexts/UnleashContext'
import { InitialVenueDocument, InitialVenueQuery, Maybe } from '@/gql/graphql'
import useClientVersionSync from '@/hooks/useClientVersionSync'
import { isLokeOrigin } from '@/hooks/useLoke'
import { useNotOutdatedBrowser } from '@/hooks/useNotOutdatedBrowser'
import { useVenueBrandingColors } from '@/hooks/useVenueBrandingColors'
import { initBugsnag } from '@/lib/bugsnag'
import { appVersion, config, releaseStage } from '@/lib/config'
import { getCurrencyForRegion } from '@/lib/currency'
import { Environment } from '@/lib/env'
import { getOrderingTypeFromSlug, OrderingTypeSlug } from '@/lib/routes'
import { serverCache } from '@/lib/serverCache'
import { urqlClient, withUrql } from '@/lib/withUrql'
import { getOrderingRevampEnabled } from '@/utils/getOrderingRevampEnabled'
import { getNestedVenueSlug, getParentVenueSlug } from '@/utils/venue'

interface Props {
  orderingTypeSlug?: OrderingTypeSlug
  venueSlug?: string
  guestVenue?: Maybe<InitialVenueQuery['guestVenue']>
  orderingRevampEnabled: boolean
}

const faviconDir = config.environment === 'production' ? 'prod' : 'dev'
const defaultCurrency = getCurrencyForRegion(config.region)

export const reportWebVitals = ({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) => {
  if (window?.gtag && value > 0) {
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      event_label: id,
      non_interaction: true,
    })
  }
}

const InitBugsnag = () => {
  if (!isClient) return null
  initBugsnag(config.bugsnagKey, releaseStage as Environment, appVersion)
  return null
}

/*
  This should be typed to have venueSlug and orderingTypeSlug?
*/
const MrYumApp = ({
  Component,
  pageProps,
  router,
}: AppProps<Props> & Props) => {
  const guestVenue: Maybe<InitialVenueQuery['guestVenue']> =
    pageProps?.guestVenue
  const venueSlug: string | undefined = pageProps?.venueSlug
  const orderingTypeSlug: OrderingTypeSlug | undefined =
    pageProps?.orderingTypeSlug
  const customTheme = useVenueBrandingColors(guestVenue)
  const { query } = useRouter()

  const { refreshRequired, tryFetchClientVersion } = useClientVersionSync()

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      if (refreshRequired && routeAllowedForFullRefresh(url)) {
        setTimeout(() => {
          window.location.href = url
        }, 0)

        // Do not change the order of the following two lines.
        // Emitting the routeChangeError event before throwing the error is essential to halting the current navigation.
        router.events.emit('routeChangeError')
        throw new Error('Navigation blocked')
      }
      void tryFetchClientVersion()
    }

    Router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [refreshRequired, router.events, tryFetchClientVersion])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (window?.gtag) {
        window.gtag('event', 'page_view', {
          page_path: url,
        })
      }
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    if (!guestVenue || !guestVenue?.googleTagManagerId) return

    let googleTagManagerId = guestVenue?.googleTagManagerId

    if (router.asPath.match(/^\/discover\/syd/)) {
      googleTagManagerId = 'GTM-M85ZCBW'
    } else if (router.asPath.match(/^\/discover\/perthairport/)) {
      googleTagManagerId = 'GTM-KXCBGDS'
    }

    ReactTagManager.init({
      code: googleTagManagerId,
      debug: false,
      performance: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestVenue])

  useNotOutdatedBrowser(router.asPath)

  const venueProps = useMemo(
    () => ({
      venueSlug: venueSlug,
      venueId: guestVenue?.id,
      organizationId: guestVenue?.organizationId,
      orderingType: orderingTypeSlug
        ? getOrderingTypeFromSlug(orderingTypeSlug)
        : undefined,
      category: query.category,
    }),
    [
      guestVenue?.id,
      guestVenue?.organizationId,
      venueSlug,
      orderingTypeSlug,
      query.category,
    ],
  )

  /*
    Components at top level need to deal with venueSlug being empty/null as we serve some pages at the base route
  */

  return (
    <>
      <Head>
        <link rel="dns-prefetch" href="https://cdn.mryum.com" />
        <link rel="dns-prefetch" href="https://api.mryum.com" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/static/favicon/${faviconDir}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/static/favicon/${faviconDir}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/static/favicon/${faviconDir}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`/static/favicon/${faviconDir}/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`/static/favicon/${faviconDir}/safari-pinned-tab.svg`}
          color="#5533ff"
        />
        <meta name="msapplication-TileColor" content="#003831" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${config.gaTrackingID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.gaTrackingID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <InitBugsnag />
      <FacebookTracking guestVenue={guestVenue} />
      <DefaultMeta />
      <VenueMetaImage venueSlug={venueSlug ?? null} />
      <UrlParamsSpy />
      <ThemeVariables
        colors={customTheme.colors}
        hasCustomBranding={customTheme.hasCustomBranding}
      />
      <ThemeProvider newUIEnabled={pageProps.orderingRevampEnabled}>
        <StripeContextProvider
          currency={guestVenue?.currency ?? defaultCurrency}
        >
          <LanguageProvider guestVenue={guestVenue}>
            <UnleashContextProvider venueSlug={venueSlug ?? null}>
              <Head>
                {/* https://github.com/vercel/next.js/issues/17721 */}
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
                />
              </Head>
              {isLokeOrigin() && (
                <Script
                  async
                  key="tidy-script"
                  src="https://static.mytidyapp.com/tidywebsdk/current/tidy.min.js"
                />
              )}
              <ThemeOverrides
                colors={customTheme.colors}
                hasCustomBranding={customTheme.hasCustomBranding}
              />
              <Toaster />
              <LoggerProvider properties={{ venue: venueProps }}>
                <EonxEnvController>
                  <AmplitudeIdentify>
                    <Component {...pageProps} />
                  </AmplitudeIdentify>
                </EonxEnvController>
              </LoggerProvider>
            </UnleashContextProvider>
          </LanguageProvider>
        </StripeContextProvider>
      </ThemeProvider>
    </>
  )
}

const getInitialProps = async (
  appContext: AppContext,
): Promise<{
  pageProps: {
    orderingTypeSlug?: OrderingTypeSlug
    venueSlug?: string
    guestVenue?: Maybe<InitialVenueQuery['guestVenue']>
  }
}> => {
  // Passing slug cookie to client-side so that we can set "x-mryum-slug"
  // header on all client-side initiated fetch requests
  const parentVenueSlug = getParentVenueSlug(appContext.ctx.query) ?? 'unknown'
  const venueSlug = getNestedVenueSlug(appContext.ctx.query)
  const orderingTypeSlug = appContext.ctx.query.orderingTypeSlug
  if (venueSlug) {
    // See: https://github.com/maticzav/nookies#reference
    // "For client side usage, omit the ctx parameter. You can do so by setting it to an empty object ({}), null or undefined."
    const ctx = isClient || !appContext.ctx.res ? null : appContext.ctx
    // Clearing previously set cookies.
    nookies.destroy(ctx, '__slug', { path: `/${venueSlug}` })
    nookies.destroy(ctx, '__slug', { path: `/${parentVenueSlug}` })
  }

  const cacheKey = `initial_venue.${venueSlug}`

  const cachedData = serverCache.get<InitialVenueQuery>(cacheKey)

  let guestVenue: InitialVenueQuery['guestVenue'] | null = null

  if (venueSlug) {
    const { data } = cachedData
      ? { data: cachedData }
      : await urqlClient(venueSlug, appContext.ctx.req, appContext.ctx.res)
          .query<InitialVenueQuery>(
            InitialVenueDocument,
            { venueSlug },
            { requestPolicy: 'network-only' },
          )
          .toPromise()

    if (data?.guestVenue) {
      serverCache.set<InitialVenueQuery>(cacheKey, data)
      guestVenue = data.guestVenue
    }
  }

  // Note: getInitialProps must come AFTER setting SSR cookie.
  // Path /[venueSlug] calls a redirect which ends the response.
  // Cookies have to be set before this is called.
  const appProps = await App.getInitialProps(appContext)

  // if there is a membershipToken, store it in a cookie
  const membershipToken = appContext.ctx.query.membershipToken
  if (membershipToken && typeof membershipToken === 'string') {
    nookies.set(appContext.ctx, 'reward-membership-token', membershipToken)
  }

  /*
    Combine venue/orderingType with page props. Some page components will fill in orderingType
    even if it's not present in URL by looking up the default venue ordering type.
  */
  return {
    ...appProps,
    pageProps: {
      orderingTypeSlug: orderingTypeSlug ? String(orderingTypeSlug) : undefined,
      venueSlug,
      guestVenue,
      orderingRevampEnabled: venueSlug
        ? await getOrderingRevampEnabled(venueSlug)
        : false,
      ...appProps.pageProps,
    },
  }
}

MrYumApp.getInitialProps = getInitialProps

export default compose(withUrql)(MrYumApp)

const routeAllowedForFullRefresh = (url: string) => {
  const pattern = '/checkout/*/pay*'
  return !minimatch(url, pattern)
}
